<template>
  <!-- 订单详情 -->
  <div class="container" :class="{ 'container-spinning': spinning }">
    <a-spin tip="Loading..." v-if="spinning"> </a-spin>

    <a-page-header
      :title="title"
      :sub-title="`订单号：${info.orderInfo.outTradeNo}`"
      @back="() => $router.go(-1)"
    >
      <template slot="extra" v-if="info">
        <a-button
          type="danger"
          @click="ondispatchMasterOpen"
          v-if="info.orderInfo.status === 1"
        >
          指派师傅接单
        </a-button>
        <a-button
          ghost
          type="danger"
          v-if="info.orderInfo.status < 6"
          @click="onCancelOrder"
        >
          取消订单
        </a-button>
        <a-button @click="onEditBookingAt" v-if="info.orderInfo.status < 3">
          修改上门时间
        </a-button>
        <!-- <a-button
          type="primary"
          @click="ondispatchMasterOpen"
          v-if="info.orderInfo.status === 1"
        >
          指派师傅
        </a-button> -->
        <!-- <a-button
          type="primary"
          @click="onSendMessage"
          v-if="info.orderInfo.status === 6"
        >
          发送验收短信
        </a-button> -->
      </template>
    </a-page-header>

    <div class="main-content">
      <div class="content-item">
        <div class="content-item-header">
          <div class="title">订单状态</div>
        </div>
        <a-steps
          :current="stepsCurrent"
          :status="stepStatus"
          progress-dot
          v-if="btype !== 'card'"
        >
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>
        <a-steps
          :current="stepsCurrent"
          :status="stepStatus"
          progress-dot
          v-else
        >
          <a-step
            v-for="item in stepsCard"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>
      </div>

      <div class="content-item" v-if="info.servicerInfo">
        <div class="content-item-header">
          <div class="title">
            <div class="title-1">接单师傅</div>
            <a-button
              type="link"
              @click="ondispatchMasterOpen"
              v-if="info.orderInfo.status === 2"
            >
              转派师傅
            </a-button>
          </div>
        </div>

        <div class="ul">
          <div class="li">
            <span>师傅姓名：</span>
            <span>{{ info.servicerInfo.realName }}</span>
          </div>
          <div class="li">
            <span>联系电话：</span>
            <span>{{ info.servicerInfo.mobile }}</span>
          </div>
          <div class="li">
            <span>常驻地区：</span>
            <span>{{ info.servicerInfo.address }}</span>
          </div>
        </div>

        <template v-if="info.apprentice">
          <a-divider />

          <div class="content-sub-title">实训学徒</div>

          <div class="ul">
            <div class="li">
              <span>学徒姓名：</span>
              <span>{{ info.apprentice.realName }}</span>
            </div>
            <div class="li">
              <span>联系电话：</span>
              <span>{{ info.apprentice.mobile }}</span>
            </div>
          </div>
        </template>
      </div>

      <div class="content-item" ref="printContent">
        <div class="content-item-header">
          <div class="title">订单详情</div>
          <div class="item print-btn">
            <a-button @click="print"> 打印订单详情 </a-button>
          </div>
        </div>

        <div class="content-sub-title">概述</div>

        <div class="ul">
          <div class="li">
            <span>订单状态：</span>
            <span>{{ $orderStatusText[info.orderInfo.status] }}</span>
          </div>
          <div class="li">
            <span>订单类型：</span>
            <span>{{ info.activityName }}</span>
          </div>
          <div class="li" v-if="info.activityServingName">
            <span>套餐名称：</span>
            <span>{{ info.activityServingName }}</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>服务项目：</span>
            <span>{{ info.orderInfo.catename }}</span>
          </div>
          <div class="li">
            <span>订单号：</span>
            <span>{{ info.orderInfo.outTradeNo }}</span>
          </div>
        </div>

        <a-divider v-if="btype !== 'card'" />

        <div class="content-sub-title" v-if="btype !== 'card'">
          <div class="title-1">上门信息</div>
          <a-button
            type="link"
            @click="onEditBookingAt"
            v-if="info.orderInfo.status < 3"
          >
            修改上门时间
          </a-button>
        </div>

        <template v-if="info.address">
          <div class="ul">
            <div class="li">
              <span>预约上门时间：</span>
              <span>{{ info.orderInfo.bookingAt }}</span>
            </div>
            <div class="li">
              <span>上门联系人：</span>
              <span>{{ info.address.name }}</span>
            </div>
            <div class="li">
              <span>联系电话：</span>
              <span>{{ info.address.mobile }}</span>
            </div>
          </div>
          <div class="ul ul-2">
            <div class="li address">
              <span>预约上门地址：</span>
              <span>
                {{ info.address.address }}{{ info.address.housenumber }}
              </span>
            </div>
          </div>
        </template>
        <a-divider />

        <div class="content-sub-title">下单设备</div>

        <a-table
          :columns="columns"
          :data-source="info.servingInfo"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 1050 }"
        >
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
        </a-table>
      </div>

      <div class="content-item" v-if="info.orderUser">
        <div class="content-item-header">
          <div class="title">下单用户</div>
          <div class="item print-btn">
            <a-button @click="onUserInfoOpen"> 用户详情 </a-button>
          </div>
        </div>

        <div class="ul">
          <div class="li">
            <span>下单用户：</span>
            <span>{{ info.orderUser.name }}</span>
          </div>
          <div class="li">
            <span>注册手机号：</span>
            <span>{{ info.orderUser.mobile }}</span>
          </div>
          <div class="li">
            <span>下单次数：</span>
            <span>{{ info.orderUser.orderNum }}</span>
          </div>
        </div>
        <div class="ul ul-2">
          <div class="li">
            <span>用户编号：</span>
            <span>{{ info.orderInfo.userId }}</span>
          </div>
        </div>

        <template v-if="info.orderInfo.status === 6">
          <a-divider />
          <div class="content-sub-title">用户评价</div>
          <div class="ul">
            <div class="li">
              <span>订单评价：</span>
              <a-rate v-model="info.orderInfo.grade" disabled />
            </div>
            <div class="li">
              <span>评价时间：</span>
              <span>{{ info.orderInfo.gradeAt }}</span>
            </div>
          </div>
        </template>
      </div>

      <div class="content-item">
        <div class="content-item-header">
          <div class="title">收款明细</div>
        </div>
        <div class="content-sub-title">金额概览</div>
        <div class="ul">
          <div class="li">
            <span>订单总金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.totalFee"
            />
          </div>
          <div class="li">
            <span>实际付款金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.paidFee"
            />
          </div>
          <div class="li">
            <span>优惠金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.discountFee"
            />
          </div>
        </div>
        <template v-if="info.couponInfo">
          <a-divider />
          <div class="content-sub-title">优惠明细</div>
          <div class="ul">
            <div class="li">
              <span>代金券类型：</span>
              <span>{{ $stockTypes[info.couponInfo.type] }}</span>
            </div>
            <div class="li">
              <span>代金券名称：</span>
              <span>{{ info.couponInfo.name }}</span>
            </div>
            <div class="li">
              <span>代金券面值：</span>
              <span>{{ info.couponInfo.amount }}元</span>
            </div>
          </div>
          <div class="ul ul-2">
            <div class="li">
              <span>代金券ID：</span>
              <span>{{ info.couponInfo.promotion_id }}</span>
            </div>
            <div class="li">
              <span>批次ID：</span>
              <span>{{ info.couponInfo.activity_id }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="embedded-page-wrap" v-if="isUserInfo">
      <div class="embedded-page">
        <div class="embedded-page-header">
          <div class="embedded-page-header-text">用户详情</div>
          <div class="embedded-page-close-btn" @click="onUserInfoClose">
            <a-icon type="close" />
          </div>
        </div>
        <user-info :proQuery="proQuery" />
      </div>
    </div>

    <a-modal
      title="修改上门时间"
      :visible="modalVisible"
      :confirm-loading="modalConfirmLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-date-picker show-time placeholder="上门时间" v-model="datePicker" />
    </a-modal>

    <a-modal
      :title="dispatchMasterData.title"
      :width="816"
      :visible="isDispatchMaster"
      @cancel="ondispatchMasterClose"
    >
      <div class="dispatch-master-title-1">上门信息</div>
      <div class="ul">
        <div class="li">
          <span class="label">预约上门时间：</span>
          <span class="value">
            {{ dispatchMasterData.bookingAt }}
          </span>
        </div>
        <div class="li">
          <span class="label">上门联系人：</span>
          <span class="value">
            {{ dispatchMasterData.name }}
          </span>
        </div>
        <div class="li">
          <span class="label">联系电话：</span>
          <span class="value">
            {{ dispatchMasterData.mobile }}
          </span>
        </div>
      </div>
      <div class="ul-2">
        <div class="li">
          <span class="label">预约上门地址：</span>
          <span class="value">
            {{ dispatchMasterData.address }}
          </span>
        </div>
      </div>
      <a-divider />
      <div class="dispatch-master-title-2">接单师傅</div>
      <div class="ul">
        <div class="li" v-if="dispatchMasterData.servicerName">
          <span class="label">当前接单师傅：</span>
          <span class="value">{{ dispatchMasterData.servicerName }}</span>
        </div>
        <div class="li dispatch-master">
          <div class="select-box">
            <span v-if="dispatchMasterData.servicerName">转派新师傅：</span>
            <span v-else>指定一名师傅：</span>
            <a-select
              placeholder="选择师傅"
              v-model="dispatchMasterData.dispatchMasterId"
            >
              <a-select-option
                v-for="item in dispatchMasters"
                :key="item.id"
                :value="item.id"
              >
                {{ item.realName }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="ondispatchMasterClose"> 取 消 </a-button>
        <a-button
          class="search-btn"
          type="primary"
          key="submit"
          :loading="dispatchMasterBtnLoading"
          @click="onDispatchMaster"
          v-if="dispatchMasterData.dispatchType === 1"
        >
          确认派单
        </a-button>
        <a-button
          class="search-btn"
          type="danger"
          key="submit"
          :loading="dispatchMasterBtnLoading"
          @click="onDispatchMaster"
          v-else
        >
          确认转派
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userInfo from './user-info'
import { formatTime } from '../../utils/public'
import moment from 'moment'
import NP from 'number-precision'
let servingInfoLength = 0
const renderContent = (text, record, index) => {
  const obj = {
    children: text,
    attrs: {}
  }
  if (index === servingInfoLength) {
    obj.attrs.colSpan = 0
  }
  return obj
}
const dispatchMasterData = {
  dispatchType: 1, // 1：指定；2：转派
  title: '',
  orderId: '',
  name: '',
  mobile: '',
  address: '',
  bookingAt: '',
  areaId: '',
  servicerName: '',
  dispatchMasterId: undefined
}
export default {
  data() {
    return {
      proQuery: {},
      spinning: true,
      title: '',
      info: {
        orderInfo: {
          outTradeNo: ''
        },
        servicerInfo: null
      },
      stepsCurrent: 0,
      stepStatus: 'process',
      steps: [
        {
          title: '提交订单',
          description: ''
        },
        {
          title: '支付订单',
          description: ''
        },
        {
          title: '确认接单',
          description: ''
        },
        {
          title: '开始上门',
          description: ''
        },
        {
          title: '到达/开始服务',
          description: ''
        },
        {
          title: '服务完成',
          description: ''
        },
        {
          title: '服务验收与评价',
          description: ''
        }
      ],
      stepsCard: [
        {
          title: '提交订单',
          description: ''
        },
        {
          title: '支付订单',
          description: ''
        },
        {
          title: '开始服务',
          description: ''
        },
        {
          title: '服务完成',
          description: ''
        },
        {
          title: '服务验收与评价',
          description: ''
        }
      ],
      columns: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => {
            if (index < servingInfoLength) {
              return index + 1
            }
            return {
              children: <span style="font-weight: bold;">{text}</span>,
              attrs: {
                colSpan: 4
              }
            }
          }
        },
        {
          title: '服务项目',
          dataIndex: 'servingName',
          width: 200,
          customRender: renderContent
        },
        {
          title: '家电名称',
          dataIndex: 'infoName',
          width: 200,
          customRender: renderContent
        },
        {
          title: '家电属性',
          dataIndex: 'subtitle',
          customRender: renderContent
        },
        {
          title: '家电数量（件）',
          dataIndex: 'quantity',
          width: 150
        },
        {
          title: '金额',
          width: 100,
          scopedSlots: { customRender: 'price' }
        }
      ],
      quantity: 0,
      isUserInfo: false,
      datePicker: '',
      modalVisible: false,
      modalConfirmLoading: false,
      dispatchMasters: [],
      isDispatchMaster: false,
      dispatchMasterBtnLoading: false,
      dispatchMasterData: JSON.parse(JSON.stringify(dispatchMasterData))
    }
  },
  computed: {
    ...mapState(['$stockTypes', '$orderStatusText'])
  },
  components: {
    userInfo
  },
  created() {
    this.title = this.$getPageTitle()
    const id = this.$route.query.id
    this.btype = this.$route.query.type
    console.log('id', this.$route.query)

    if (this.btype !== 'card') {
      this.getOrderItemInfo(id)
    } else {
      this.getServingOrderInfo(id)
    }
  },
  methods: {
    getOrderItemInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getOrderItemInfo(data).then((res) => {
        const d = res.data.data
        if (d.couponInfo) {
          d.couponInfo.amount = NP.divide(d.couponInfo.amount, 100)
        }
        servingInfoLength = d.servingInfo.length
        const status = d.orderInfo.status // 订单状态 0：待支付 1：待确认 2：待上门 3：开始上门 4：开始服务 5：服务完成 6：验收评价 7: 用户取消订单 8：平台取消订单
        const times = [
          'createAt', // 创建时间
          'payAt', // 用户支付时间
          'acceptAt', // 接单时间
          'visitingAt', // 上门时间
          'servingAt', // 服务时间
          'finishAt', // 完成时间
          'checkAt' // 验收评价时间
        ]
        this.steps.forEach((e, i) => {
          if (d.orderInfo[times[i]]) {
            const t = formatTime(d.orderInfo[times[i]])
            e.description = t
          }
        })
        if (status < 7) {
          this.stepsCurrent = status
        } else {
          this.stepsCurrent = d.orderInfo.beforeStatus
          const step = this.steps[d.orderInfo.beforeStatus]
          step.title = this.$orderStatusText[status]
          step.description = d.orderCancel.finshAt
          this.stepStatus = 'error'
        }

        let quantity = 0
        d.servingInfo.forEach((e) => {
          quantity += Number(e.quantity)
        })
        const row = {
          id: 0,
          sort: '总计',
          servingName: '',
          infoName: '',
          subtitle: '',
          quantity: quantity,
          price: d.orderInfo.totalFee
        }
        d.servingInfo.push(row)
        this.info = d
        this.spinning = false
      })
    },
    getServingOrderInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getServingOrderInfo(data).then((res) => {
        const d = res.data.data
        if (d.couponInfo) {
          d.couponInfo.amount = NP.divide(d.couponInfo.amount, 100)
        }
        servingInfoLength = d.servingInfo.length
        const status = d.orderInfo.status // 订单状态 0：待支付 1：待确认 2：待上门 3：开始上门 4：开始服务 5：服务完成 6：验收评价 7: 用户取消订单 8：平台取消订单
        const times = [
          'createAt', // 创建时间
          'payAt', // 用户支付时间
          'acceptAt', // 接单时间
          'visitingAt', // 上门时间
          'servingAt', // 服务时间
          'finishAt', // 完成时间
          'checkAt' // 验收评价时间
        ]
        this.steps.forEach((e, i) => {
          if (d.orderInfo[times[i]]) {
            const t = formatTime(d.orderInfo[times[i]])
            e.description = t
          }
        })
        if (status < 7) {
          this.stepsCurrent = status
        } else {
          this.stepsCurrent = d.orderInfo.beforeStatus
          const step = this.steps[d.orderInfo.beforeStatus]
          step.title = this.$orderStatusText[status]
          step.description = d.orderCancel.finshAt
          this.stepStatus = 'error'
        }

        let quantity = 0
        d.servingInfo.forEach((e) => {
          quantity += Number(e.quantity)
        })
        const row = {
          id: 0,
          sort: '总计',
          servingName: '',
          infoName: '',
          subtitle: '',
          quantity: quantity,
          price: d.orderInfo.totalFee
        }
        d.servingInfo.push(row)
        this.info = d
        this.spinning = false
      })
    },
    print() {
      // 打印
      const content = this.$refs.printContent.innerHTML
      const printDiv = document.createElement('div')
      printDiv.innerHTML = content
      printDiv.style.position = 'fixed'
      printDiv.style.left = '0'
      printDiv.style.top = '0'
      printDiv.style.width = '100%'
      printDiv.style.height = '100%'
      printDiv.style.zIndex = '100000'
      printDiv.style.background = '#fff'
      printDiv.style.overflow = 'auto'
      document.body.appendChild(printDiv)
      window.print()
      document.body.removeChild(printDiv)
    },
    onUserInfoClose() {
      // 关闭用户详情
      // this.$router.replace({
      //   query: {
      //     id: this.info.orderInfo.orderId
      //   }
      // })
      this.isUserInfo = false
    },
    onUserInfoOpen() {
      // 查看用户详情
      // this.$router.replace({
      //   query: {
      // id: this.info.orderInfo.userId,
      //  btype:"normal"
      //   }
      // })
      this.proQuery = {
        id: this.info.orderInfo.userId,
        btype: this.btype
      }
      this.isUserInfo = true
    },
    onEditBookingAt() {
      // 修改上门时间
      this.modalVisible = true
    },
    handleModalCancel() {
      // 取消修改上门时间
      this.datePicker = ''
      this.modalVisible = false
      this.modalConfirmLoading = false
    },
    handleModalOk() {
      // 确定修改上门时间
      this.modalConfirmLoading = true
      const data = {
        orderId: this.info.orderInfo.orderId,
        bookingAt: moment(this.datePicker).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$axios
        .changeOrderItemTime(data)
        .then(() => {
          this.modalConfirmLoading = false
          this.$message.success('操作成功')
          this.handleModalCancel()
          this.getOrderItemInfo(this.info.orderInfo.orderId)
        })
        .catch(() => {
          this.handleModalCancel()
        })
    },
    onCancelOrder() {
      // 取消订单
      this.$confirm({
        title: '确定取消订单?',
        onOk: () => {
          const data = {
            orderId: this.info.orderInfo.orderId
          }
          this.$axios.cancelOrderItem(data).then(() => {
            this.$message.success('操作成功')
            this.getOrderItemInfo(this.info.orderInfo.orderId)
          })
        }
      })
    },
    ondispatchMasterOpen() {
      // 打开转派/指定派单
      const order = this.info.orderInfo
      const user = this.info.address
      const servicer = this.info.servicerInfo
      let title = ''
      let dispatchType = 1
      let realName = ''
      if (servicer) {
        // 转派
        dispatchType = 2
        realName = servicer.realName
        title = `转派师傅 - ${order.catename}（订单号：${order.outTradeNo}）`
      } else {
        // 指定
        title = `指定派单 - ${order.catename}（订单号：${order.outTradeNo}）`
      }
      this.dispatchMasterData = {
        orderId: order.orderId,
        title: title,
        dispatchType: dispatchType,
        name: user.name,
        mobile: user.mobile,
        address: `${user.province}${user.city}${user.district}-${user.address}`,
        servicerName: realName,
        bookingAt: order.bookingAt,
        areaId: order.areaId,
        dispatchMasterId: undefined
      }

      this.getDispatchMaster()
      this.isDispatchMaster = true
    },
    ondispatchMasterClose() {
      // 关闭转派/指定派单
      this.dispatchMasterData = JSON.parse(JSON.stringify(dispatchMasterData))
      this.isDispatchMaster = false
    },
    getDispatchMaster() {
      // 可转派师傅
      const data = {
        areaId: this.dispatchMasterData.areaId
      }
      this.$axios.getDispatchMaster(data).then((res) => {
        this.dispatchMasters = res.data.data
      })
    },
    onDispatchMaster() {
      // 转派/指定派单
      if (!this.dispatchMasterData.dispatchMasterId) {
        this.$message.error('请先选择师傅')
        return
      }
      const data = {
        orderId: this.dispatchMasterData.orderId,
        servicerId: this.dispatchMasterData.dispatchMasterId
      }
      this.dispatchMasterBtnLoading = true
      this.$axios
        .dispatchMaster(data)
        .then(() => {
          this.dispatchMasterBtnLoading = false
          this.$message.success('操作成功')
          this.ondispatchMasterClose()
          this.getOrderItemInfo(this.info.orderInfo.orderId)
        })
        .catch(() => {
          this.dispatchMasterBtnLoading = false
        })
    },
    onSendMessage() {
      // 发送验收短息
      this.$confirm({
        icon: () => <a-icon theme="filled" type="exclamation-circle" />,
        title: '确认发送验收短信',
        content:
          '阿里云短信内容：【阿六到家】亲爱的用户，您的订单还未验收，点击链接验收：https://wxaurl.cn/biXmOd1fRGd。',
        onOk: () => {
          const data = {
            orderId: this.info.orderInfo.orderId
          }
          this.$axios.sendMessage(data).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.container {
  position: relative;
}

.container-spinning {
  height: 100vh;
  overflow: hidden;
}

.ant-spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.ant-page-header {
  position: inherit;
  z-index: 10;
}

.content-item:first-child {
  padding: 0;
}

.content-item:first-child .content-item-header {
  padding: 16px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.content-sub-title,
.content-item-header .title {
  display: flex;
  align-items: center;
}

.dispatch-master-title-1 {
  font-size: 14px;
  line-height: 22px;
  color: #333;
  font-weight: bold;
  margin-bottom: 16px;
}

.dispatch-master-title-2 {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.ul .dispatch-master {
  width: auto;
}

.select-box {
  display: flex;
  align-items: center;
}

.select-box .ant-select {
  width: 240px;
}

@media print {
  .print-btn {
    display: none;
  }
}
</style>
